import React from "react";
import { useTranslation } from "react-i18next";
import "./AlbumPromo.css";

const AlbumPromo = () => {
  const { t } = useTranslation();

  return (
    <div id="album-promo-container">
      <div id="album-cover-wrapper">
        <h1 className="title">{t("albumPromo.availableNow")}</h1>
        <img
          src={require("./assets/HBD.webp")}
          alt="Human by Design Album Cover"
          id="album-cover"
        />
      </div>
      <div id="spotify-player-wrapper">
        <iframe
          id="spotify-player"
          title="Spotify"
          src="https://open.spotify.com/embed/album/6wmECAwMN2F7dsXdRoIKxk?utm_source=generator&theme=0"
          width="100%"
          frameBorder="0"
          height="452"
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture"
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
};

export default AlbumPromo;
