import React from "react";
import { useTranslation } from "react-i18next";
import "./LanguageSelector.css";

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <div className="language-selector">
      <select className="language-select" onChange={changeLanguage}>
        <option value="en">English</option>
        <option value="hu">Magyar</option>
      </select>
    </div>
  );
};

export default LanguageSelector;
