import React from "react";
import "./Footer.css";
import LanguageSelector from "../language-selector/LanguageSelector";

const Footer = () => {
  return (
    <footer id="footer">
      <LanguageSelector />
    </footer>
  );
};

export default Footer;
